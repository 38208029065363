import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import plTranslations from './lang/pl.json';
import enTranslations from './lang/en.json';
import deTranslations from './lang/de.json';


// the translations
const resources = {
    "pl": {
        translation: plTranslations
    },
    "en": {
        translation: enTranslations
    },
    "de": {
        translation: deTranslations
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        resources,
        fallbackLng: 'en',
        keySeparator: '.', // '.' jesli wielopoziomy json, false jesli nie
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;